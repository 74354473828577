import * as React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import { FaWhatsapp } from "react-icons/fa";

import Layout from "../components/layout";
import SEO from "../components/seo";
import NewsletterBanner from "../components/newsletterBanner";

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="Contattaci - Sterea Medicina e Viaggi" />

      <div className="sterea-center-column-wrapper py-12 px-min sterea-blue-gradient text-white">
        <form
          name="contact"
          method="post"
          className="sterea-center-column flex flex-wrap"
          data-netlify="true"
          action="/thank-you"
        >
          <input type="hidden" name="form-name" value="contact" />
          <div className="w-full lg:w-1/2 lg:pr-6">
            <h2 className="mb-12">Contattaci</h2>
            <ul>
              <li>
                Compila il modulo qui{" "}
                <span className="hidden lg:inline">a lato</span>
                <span className="lg:hidden">sotto</span>
              </li>
              <li>
                <a href="tel:3792508156">
                  Chiamaci <span className="font-bold">+39 3792508156</span>
                </a>
              </li>
              <li>
                <a href="https://wa.me/393792508156">
                  Clicca qui per scrivici su{" "}
                  <span className="font-bold">WhatsApp</span>{" "}
                  <FaWhatsapp className="inline" />
                </a>
              </li>
            </ul>
            <div className="flex items-start lg:items-center mt-6 mb-6 lg:mb-0">
              <div className="text-center lg:text-left w-1/3 lg:w-1/4 mr-6 flex">
                <StaticImage
                  quality={100}
                  src="../images/laura_profile.png"
                  placeholder="none"
                  layout="constrained"
                  alt="Foto di profilo di Laura Serzentaite, co-fondatrice di Sterea Medicina e Viaggi"
                />
              </div>
              <div className="w-2/3 lg:w-3/4 text-white">
                Risponderemo a tutte
                le tue domande e ti consiglieremo per il meglio
              </div>
            </div>
          </div>
          <div className="w-full lg:w-1/2 lg:pl-6">
            <div className="flex flex-col text-black">
              <input
                type="text"
                placeholder="Nome*"
                name="nome"
                className="bg-white p-3 outline-none rounded mb-6"
                required
              />
              <input
                type="email"
                placeholder="Email*"
                name="email"
                className="bg-white p-3 outline-none rounded mb-6"
                required
              />
              <textarea
                placeholder="Vorrei sapere..."
                name="testo"
                className="bg-white p-3 h-24 outline-none rounded mb-6"
              />
              <div className="flex justify-center">
                <input
                  type="submit"
                  value="Invia"
                  className="sterea-yellow-button"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
    </Layout>
  );
};

export default IndexPage;
